
export interface TemplateCategoryDetails {
  _id?: string;
  categoryName?: string;
  categoryColor?:string;
  isActive?: Boolean;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum activeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}