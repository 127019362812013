import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Card, Box, CardHeader, FormControl, InputLabel, Select, MenuItem, Divider, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Typography, Tooltip, IconButton, TablePagination, useTheme } from "@mui/material";
import BulkActions from "../../Transactions/BulkActions";
import numeral from 'numeral';;
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import UserContext from "src/context/UserContext";
import { ManagementServices } from "src/services/ManagementServices";
import Swal from "sweetalert2";
import { ChapterDetails } from './../../../../models/Chapter';
import { ItemDetails } from "src/models/Item";
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import Button from '@mui/material/Button';
import CustomizedDialogs from "../../Users/allUsers/CustomizedDialogs";
import dayjs from "dayjs";
import SearchBar from "./SearchBar";
import moment from "moment";
import ChapterCustomizedDialogs from "./ChapterCustomizedDialogs";
// const [user, setUser] = useContext(UserContext);


interface AllUserTableProps {
  className?: string;
  allChapters: ChapterDetails[];
}

interface Filters {
  status?: any;
  date?:any;
}
enum PageType {
  Users = 'Users',
  Chapters = 'Chapters',
  Items = 'Items'
}
enum DateFilter {
  ALL = 'ALL',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM'
}
const applyFilters = (
  AllChapters: ChapterDetails[],
  filters: Filters
): any[] => {
  return AllChapters.filter((chapter) => {
    let matches = true;

    if (filters.status && chapter.status !== filters.status) {
      matches = false;
    }

    if (filters.date) {
      const currentDate = new Date();
      const chapterDate = new Date(chapter.createdAt);

      switch (filters.date) {
        case DateFilter.LAST_WEEK:
          const lastWeek = new Date();
          lastWeek.setDate(lastWeek.getDate() - 7);
          if (chapterDate < lastWeek) {
            matches = false;
          }
          break;

        case DateFilter.LAST_MONTH:
          const lastMonth = new Date();
          lastMonth.setDate(lastMonth.getDate() - 30);
          if (chapterDate < lastMonth) {
            matches = false;
          }
          break;

        // Handle other cases if needed
      }
    }

    return matches;
  });
};

const applyPagination = (
  allChapters: ChapterDetails[],
  page: number,
  limit: number
): any[] => {
  return allChapters.slice(page * limit, page * limit + limit);
};
const applyPaginationChapter = (
  allchapters: ChapterDetails[],
  page: number,
  limit: number
): any[] => {
  return allchapters.slice(page * limit, page * limit + limit);
};

const applyPaginationItem = (
  allItems: ItemDetails[],
  page: number,
  limit: number
): any[] => {
  return allItems.slice(page * limit, page * limit + limit);
};

const AllChaptersTable: FC = () => {
  const [selectedAllChapters, setSelectedAllChapters] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedAllChapters.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const [allChapters, setAllChapters] = useState([] as ChapterDetails[]);
  const [allchapters, setAllchapters] = useState([] as ChapterDetails[]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<DateFilter | string>(DateFilter.ALL);
  // const [date, setDate] = useState<number>(0);
  const [allitems, setAllItems] = useState([] as ItemDetails[]);
  const [pagetype, setPagetype] = useState<PageType>(PageType.Chapters);
  const [chapterName, setChapterName] = useState('');
  const LIMIT_P_C = 10;
  const LIMIT_A_C = 10;
  const LIMIT_B_C = 10;

  const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);

  const [isOffset_a_c, setOffset_a_c] = useState<number>(0);
  const [seeMore_a_c, setSeeMore_a_c] = useState(false);

  const [isOffset_b_c, setOffset_b_c] = useState<number>(0);
  const [seeMore_b_c, setSeeMore_b_c] = useState(false);

  useEffect(() => {
    getChapters();

  }, []);

  // const getChapters = () => {
  //   ManagementServices.getAllChapters(LIMIT_P_C, isOffset_p_c).then(res => {
  //     if (res.success) {
  //       setAllChapters(res.data);
  //       setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
  //     } else {
  //       setSeeMore_p_c(false);
  //     }

  //   });
  // }

  const getChapters = () => {
    ManagementServices.getAllChapters(LIMIT_P_C, isOffset_p_c).then(res => {
      if (res.success) {
        const filteredChapters = applyFilters(res.data, filters);  // Apply status and date filters
        setAllChapters(filteredChapters);
        setSeeMore_p_c(filteredChapters.length > 0 && filteredChapters.length === LIMIT_P_C);
      } else {
        setSeeMore_p_c(false);
      }
    });
  };
  

  const getItems = (Chapter: ChapterDetails | undefined) => {
    if (Chapter.itemCount != 0) {
      setPagetype(PageType.Items)
      ManagementServices.getAllItemsWithChapterIdbyAdmin(Chapter._id).then(res => {
        if (res.success) {
          setAllItems(res.data);
          setSeeMore_b_c(res.data.length > 0 && res.data.length == LIMIT_B_C);
        } else {
          setSeeMore_b_c(false);
        }

      });
    }
  }


  // const updateChapter = (user: ChapterDetails | undefined) => {
  //   if (user) {
  //     Swal.fire({
  //       title: "Change Status",
  //       input: "select",
  //       inputOptions:VerifyStatus,
  //       inputValue: user.status,
  //       inputAttributes: {
  //         autocapitalize: "off",
  //         placeholder: 'Update User',
  //       },
  //       showCancelButton: true,
  //       confirmButtonText: "Update",
  //       showLoaderOnConfirm: true,
  //       confirmButtonColor: "#FD7F00",
  //     }).then(result => {
  //       if (result.isConfirmed) {
  //         if (result.value == "" || result.value == undefined || result.value == null) {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Invalid value. Please try again later.",
  //             confirmButtonColor: "#FD7F00",
  //           });
  //         } else {
  //           const data = {
  //             userId: user?._id,
  //             status: result.value,
  //           };
  //           ManagementServices.updateChapter(data).then(res => {
  //             if (res.success) getChapters();
  //             else {
  //               Swal.fire({
  //                 icon: "error",
  //                 title: res.error,
  //                 confirmButtonColor: "#FD7F00",
  //               });
  //             }
  //           });
  //         }
  //       }
  //     });
  //   }
  // };

  const resetSearch = () => {
    setChapterName('');
    getChapters(); // Fetch all chapters again
  };
  
  const statusOptions = [
    {
      id: 'ALL',
      name: 'ALL'
    },
    {
      id: 'PENDING',
      name: 'PENDING'
    },
    {
      id: 'VERIFIED',
      name: 'VERIFIED'
    },
    {
      id: 'BLOCKED',
      name: 'BLOCKED'
    },
  ];

  const dateOptions = [
    {
      name: 'ALL',
      id: 'ALL'
    },
    {
      name: 'LAST WEEK',
      id: 'LAST_WEEK'
    },
    {
      name: 'LAST MONTH',
      id: 'LAST_MONTH'
    },
    {
      name: 'CUSTOM',
      id: 'CUSTOM'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'ALL') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const setDateFilteredChapters = (startValue, endValue) => {
    ManagementServices.getAllClientsByDateRange(startValue, endValue).then(
      (res) => {
        if (res.success) {
          setAllChapters(res.data);

          setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
        } else {
          setSeeMore_p_c(false);
        }
      }
    );
  };

  const setChaptersByChapterName = () => {
    ManagementServices.searchChaptersByChapterName({searchTag: chapterName}).then(
      (res) => {
        if (res.success) {
          setAllChapters(res.data);

          setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
        } else {
          setSeeMore_p_c(false);
        }
      }
    );
  };

  // const handleDateFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
  //   const { value } = e.target;
  //   setDateFilter(value);

  //   switch (value) {
  //     case DateFilter.ALL:
  //       getChapters();
  //       break;

  //     case DateFilter.LAST_WEEK:
  //       setDateFilteredChapters(dayjs(new Date().setDate(new Date().getDate() - 7)).format('YYYY-MM-DD'), dayjs(new Date(Date.now())).format('YYYY-MM-DD'));
  //       break;

  //     case DateFilter.LAST_MONTH:
  //       setDateFilteredChapters(dayjs(new Date().setDate(new Date().getDate() - 30)).format('YYYY-MM-DD'), dayjs(new Date(Date.now())).format('YYYY-MM-DD'));
  //       break;

  //     case DateFilter.CUSTOM:
  //       getChapters();
  //       setModalOpen(true);
  //       break;

  //     default:
  //       getChapters();
  //       break;
  //   }

  // };

  // const handleDateFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
  //   const { value } = e.target;
  //   setDateFilter(value);
  
  //   switch (value) {
  //     case DateFilter.ALL:
  //       setFilters((prevFilters) => ({
  //         ...prevFilters,
  //         date: null  // No need to filter by date
  //       }));
  //       break;
  
  //     case DateFilter.LAST_WEEK:
  //     case DateFilter.LAST_MONTH:
  //       setFilters((prevFilters) => ({
  //         ...prevFilters,
  //         date: value
  //       }));
  //       break;
  
  //     case DateFilter.CUSTOM:
        
  //       // Handle custom date filter logic
  //       setModalOpen(true);
  //       break;
  
  //     default:
  //       break;
  //   }
  // };
  const handleDateFilterChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setDateFilter(value);
  
    switch (value) {
      case DateFilter.ALL:
      case DateFilter.LAST_WEEK:
      case DateFilter.LAST_MONTH:
        setFilters((prevFilters) => ({
          ...prevFilters,
          date: value
        }));
        break;
  
      case DateFilter.CUSTOM:
        setFilters((prevFilters) => ({
          ...prevFilters,
          date: null // Reset date filter when switching to "Custom"
        }));
        setModalOpen(true);
        break;
  
      default:
        // Handle other cases if needed
        break;
    }
  };
  
  // Use useEffect to call getUsers when the date filter is set to "ALL", "LAST_WEEK", or "LAST_MONTH"
  useEffect(() => {
    const fetchData = async () => {
      await getChapters();
    };
  
    if (dateFilter === DateFilter.ALL || dateFilter === DateFilter.LAST_WEEK || dateFilter === DateFilter.LAST_MONTH) {
      fetchData();
    }
  }, [dateFilter]);
  


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredAllChapters = applyFilters(allChapters, filters);
  
  const paginatedAllChapters = applyPagination(
    filteredAllChapters,
    //allChapters,
    page,
    limit
  );
  // const paginatedAllChapters = applyPaginationChapter(allchapters, page, limit);
  
  const searchBarStyles = {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '20px',
      paddingRight: '20px',
      height: '50px',
      backgroundColor: '#ffffff',
      borderBottom: '1px solid rgba(0,0,0,0.12)'

    },
    buttonwrap: { padding: '7px' },
  }
  
  const paginatedAllItems = applyPaginationItem(allitems, page, limit);

  const selectedSomeCryptoOrders =
    selectedAllChapters.length > 0 &&
    selectedAllChapters.length < allChapters.length;
  const selectedAllCryptoOrders =
    selectedAllChapters.length === allChapters.length;
  const theme = useTheme();

  return (
    <>
      {pagetype == PageType.Chapters && (
        <Card>
          {selectedBulkActions && (
            <Box flex={1} p={2}>
              <BulkActions />
            </Box>
          )}
          {!selectedBulkActions && (
            <CardHeader
              action={
                <Box width={1000}
                  display={'flex'}
                  flexDirection={'row'}
                  gap={'10px'}>
                  <Box sx={searchBarStyles.wrapper}>
                    {/* <SearchBar

                      Placeholder="Search by Chapter Name"
                      value={chapterName}
                      onChange={e => setChapterName(e.target.value)}
                      searchBarWidth='400px'

                    /> */}
                    <SearchBar
                      Placeholder="Search by Chapter Name"
                      value={chapterName}
                      onChange={(e) => {
                        const value = e.target.value;
                        setChapterName(value);
                        if (!value.trim()) {
                          resetSearch(); // Fetch all chapters when the search box is cleared
                        }
                      }}
                      searchBarWidth='400px'
                    />

                  </Box>
                  <Box sx={searchBarStyles.buttonwrap}>
                    <Button
                      variant="contained"
                      onClick={setChaptersByChapterName} >
                      Search
                    </Button>

                  </Box>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={filters.status || 'ALL'}
                      onChange={handleStatusChange}
                      label="Status"
                      autoWidth
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Filter Date</InputLabel>
                    <Select
                      value={dateFilter || DateFilter.ALL}
                      onChange={handleDateFilterChange}
                      label="dateType"
                      autoWidth
                    >
                      {dateOptions.map((dateOptions) => (
                        <MenuItem
                          key={dateOptions.id}
                          value={dateOptions.id}
                        >
                          {dateOptions.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              }
              title="All Chapters"
            />
          )}
          <Divider />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                        <Checkbox
                        color="primary"
                        checked={selectedAllCryptoOrders}
                        indeterminate={selectedSomeCryptoOrders}
                        onChange={handleSelectAllCryptoOrders}
                        />
                    </TableCell> */}
                  <TableCell>Chapter Name</TableCell>
                  <TableCell>Chapter Group</TableCell>
                  <TableCell align="center">Allocated Budget</TableCell>
                  <TableCell align="center">Owner</TableCell>
                  <TableCell align="center">Item Count</TableCell>
                  <TableCell align="center">Created at</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAllChapters.map((chapter) => {
                  const isCryptoOrderSelected = selectedAllChapters.includes(
                    chapter._id
                  );
                  return (
                    <TableRow
                    sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter,
                        //cursor: 'pointer',
                      },
                      color: theme.palette.warning.main
                  }}
                      key={chapter._id}
                     
                      selected={isCryptoOrderSelected}
                    >
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {chapter.chapterName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" noWrap>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {chapter.chapterGroup.chapterGroup}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {chapter.allotedBudget}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {chapter.createdBy?.name ? chapter.createdBy?.name : chapter.createdBy?.email}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <a onClick={() => getItems(chapter)}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                           <IconButton style={{ fontSize: 'inherit', fontWeight:"bold" }}>{chapter.itemCount ? chapter.itemCount : '-'}</IconButton>
                          </Typography>
                        </a>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                          align="center"
                        >
                          {moment(chapter.createdAt && chapter.createdAt).format("MMM Do YY")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {chapter.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={filteredAllChapters.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25, 30]}
            />
          </Box>
        </Card>
      )}
      {modalOpen && (
        <ChapterCustomizedDialogs
          body={'Open'}
          buttonTitle={'Set Filter'}
          open={modalOpen}
          setOpen={setModalOpen}
          title={'Date range picker'}
          setAllChapters={setAllChapters}
          setSeeMore_p_c={setSeeMore_p_c}
          LIMIT_P_C={LIMIT_P_C}
        />
      )}
      
      {pagetype == PageType.Items && (
        <Card>
          <Box display="flex" mb={3}>
            <Tooltip arrow placement="top" title="Go back">
              <IconButton
                onClick={() => setPagetype(PageType.Chapters)}
                color="primary"
                sx={{ p: 2, mr: 2 }}
              >
                <ArrowBackTwoToneIcon />
              </IconButton>
            </Tooltip>
            <CardHeader title=" Item List" />
          </Box>

          <Divider />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Task Name</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Task Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedAllItems.map((item) => {
                  return (
                    <TableRow  sx={{
                      '&:hover': {
                        background: theme.colors.primary.lighter,
                        //cursor: 'pointer',
                      },
                      color: theme.palette.warning.main
                  }} key={item._id}>
                      <TableCell align="center">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.itemName}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          noWrap
                        ></Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.categoryId.category}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.taskType}
                        </Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          gutterBottom
                          noWrap
                        >
                          {item.currency.currencyName}  {item.amount.toFixed(2)} 
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box p={2}>
            <TablePagination
              component="div"
              count={allitems.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25, 30]}
            />
          </Box>
        </Card>
      )}
    </>
  );
}

AllChaptersTable.propTypes = {
  allUsers: PropTypes.array.isRequired
};

AllChaptersTable.defaultProps = {
  allUsers: []
};

export default AllChaptersTable;