import { Typography } from '@mui/material';
import TemplateCategoryPopup from './template-category-popup';

function PageHeader({ TemplateCategories, setTemplateCategories }) {

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        Template Categories
      </Typography>
      <Typography variant="subtitle2">
        Template Categories of the Budget App
      </Typography>
      <TemplateCategoryPopup
        TemplateCategories={TemplateCategories}
        setTemplateCategories={setTemplateCategories}
      />
    </>
  );
}

export default PageHeader;