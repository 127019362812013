import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import {
  TemplateCategoryDetails,
  activeStatus
} from 'src/models/TemplateCategory';
import CloseIcon from '@mui/icons-material/Close';
import { SketchPicker } from 'react-color';
import moment from 'moment';
import { InventoryService } from 'src/services/InventoryService';
import { CopyAllTwoTone } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Swal from 'sweetalert2';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Filters {
  status?: any;
}

const applyFilters = (
  TemplteCategories: TemplateCategoryDetails[],
  filters: Filters
): any[] => {
  return TemplteCategories.filter((category) => {
    let matches = true;

    let statusString;
    if (category.isActive === true) {
      statusString = 'ACTIVE';
      if (filters.status && statusString !== filters.status) {
        matches = false;
      }
    } else {
      statusString = 'INACTIVE';
      if (filters.status && statusString !== filters.status) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  allCategories: TemplateCategoryDetails[],
  page: number,
  limit: number
): any[] => {
  return allCategories.slice(page * limit, page * limit + limit);
};

interface Props {
  TemplateCategories: TemplateCategoryDetails[];
  setTemplateCategories: React.Dispatch<
    React.SetStateAction<TemplateCategoryDetails[]>
  >;
}

const TemplateCategoryTable: FC<Props> = ({
  TemplateCategories,
  setTemplateCategories
}) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const [open, setOpen] = useState(false);
  const [CategoryId, setCategoryId] = useState();
  const [Status, setStatus] = useState();
  const [CategoryName, setCategoryName] = useState('');
  const [bubbleColor, setBackgroundColor] = useState('#ffffff');

  const LIMIT_P_C = 10;
  const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);

  useEffect(() => {
    getTemplateCategories();
  }, []);

  const getTemplateCategories = () => {
    InventoryService.getAllTemplateCategories(LIMIT_P_C, isOffset_p_c).then(
      (res) => {
        if (res.success) {
          setTemplateCategories(res.data);
          setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
        } else {
          setSeeMore_p_c(false);
        }
      }
    );
  };

  const handleClickOpen = (categoryid, status, category, color) => {
    setCategoryId(categoryid);
    setStatus(status);
    setCategoryName(category);
    setBackgroundColor(color);
    setOpen(true);
  };

  const updateTemplateCategory = () => {
    setOpen(false);
    // const data = {
    //   categoryId: CategoryId,
    //   iconValue:Iconid,
    //   isActive:Status,
    //   categoryName: CategoryName,
    //   color:bubbleColor,
    //   chapterId:ChapterId
    // };

    // ManagementServices.updateCategory(data).then((res) => {
    //   if (res.success) {
    //     getcategories()
    //     Swal.fire('Category Updated');
    //   } else {
    //     Swal.fire({
    //       icon: 'error',
    //       title: res.error,
    //       confirmButtonColor: '#FD7F00'
    //     });
    //   }
    // });
  };

  const deleteCategory = (categoryId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // ManagementServices.deleteCategory(categoryId).then((res) => {
        //   if (res.success) {
        //     setAllCategories(
        //       AllCategories.filter(
        //         (CategoryDetails) => CategoryDetails._id !== categoryId
        //       )
        //     );
        //     Swal.fire('Category Removed');
        //   } else {
        //     Swal.fire({
        //       icon: 'error',
        //       title: res.error,
        //       confirmButtonColor: '#FD7F00'
        //     });
        //   }
        // });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const statusOptions = [
    {
      id: 'ALL',
      name: 'ALL'
    },
    {
      id: 'ACTIVE',
      name: 'ACTIVE'
    },
    {
      id: 'INACTIVE',
      name: 'INACTIVE'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'ALL') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const statusupdateOptions = [
    {
      id: 'true',
      name: activeStatus.ACTIVE
    },
    {
      id: 'false',
      name: activeStatus.INACTIVE
    }
  ];

  const handleUpdateStatus = (event) => {
    setStatus(event.target.value);
  };

  const handlebackgroundColorChange = (color: any) => {
    setBackgroundColor(color.hex);
  };

  const handleCopy = async (categoryId) => {
    await navigator.clipboard.writeText(categoryId);
    toast('Copied to clipboard');
  };

  const filteredAllCategories = applyFilters(TemplateCategories, filters);
  const paginatedAllCategories = applyPagination(
    filteredAllCategories,
    page,
    limit
  );
  const theme = useTheme();

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Update Category Icon
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <InputLabel>Status</InputLabel>
          <Select
            style={{ width: `300px` }}
            id="filled-select-currency"
            variant="filled"
            value={Status}
            onChange={handleUpdateStatus}
            label="Status"
            autoWidth
          >
            {statusupdateOptions.map((statusupdateOption) => (
              <MenuItem
                style={{ width: `300px` }}
                key={statusupdateOption.id}
                value={statusupdateOption.id}
              >
                {statusupdateOption.name}
              </MenuItem>
            ))}
          </Select>
          <div style={{ height: 10 }}></div>
          <TextField
            fullWidth
            id="category"
            name="category"
            label="category Name"
            value={CategoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <div style={{ height: 10 }}></div>
          <SketchPicker
            color={bubbleColor}
            onChangeComplete={handlebackgroundColorChange}
          />
          <div style={{ height: 10 }}></div>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={() => updateTemplateCategory()}
          >
            Update
          </Button>
        </DialogContent>
      </BootstrapDialog>

      <Card>
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'ALL'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title="Template Category"
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedAllCategories.map((category) => {
                return (
                  <TableRow hover key={category._id}>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {category.categoryName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {category.isActive === true ? 'ACTIVE' : 'INACTIVE'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {moment(
                          category.createdAt && category.createdAt
                        ).format('MMM Do YY')}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="Copy Id" arrow>
                        <IconButton
                          onClick={() => handleCopy(category._id)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.warning.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <CopyAllTwoTone fontSize="small" />
                          <ToastContainer
                            position="bottom-right"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover={false}
                            theme="dark"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit category" arrow>
                        <IconButton
                          onClick={() =>
                            handleClickOpen(
                              category._id,
                              category.isActive,
                              category.categoryName,
                              category.categoryColor
                            )
                          }
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Category" arrow>
                        <IconButton
                          onClick={() => deleteCategory(category._id)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.error.lighter
                            },
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default TemplateCategoryTable;
