import { Typography } from "@mui/material";

function PageHeader({ AllUserCategories, setAllUserCategories }) {
    return (
        <>
            <Typography variant="h3" component="h3" gutterBottom>
                All User Categories
            </Typography>
            <Typography variant="subtitle2">
                All User Categories of the Budget App
            </Typography>
        </>
    );
};

export default PageHeader;