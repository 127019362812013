import { Box, Card, CardHeader, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { ChapterDetails } from "src/models/Chapter";
import { ItemDetails } from "src/models/Item";
import { ManagementServices } from "src/services/ManagementServices";
import BulkActions from "../Transactions/BulkActions";
import { CurrencyDetails } from "src/models/Currency";
import moment from "moment";

interface Props {
    chapterID?: string;
}

const applyPagination = (allItems: ItemDetails[], page: number, limit: number): any[] => {
    return allItems.slice(page * limit, page * limit + limit);
}


const AllTasksTable: FC<Props> = (props) => {

    const [selectedAllItems, setselectedAllItems] = useState<string[]>([]);
    const selectedBulkActions = selectedAllItems.length > 0;
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);

    const [allChapters, setAllChapters] = useState([] as ChapterDetails[]);
    const [allItems, setAllItems] = useState([] as ItemDetails[]);
    const [allCurrency, setAllCurrency] = useState([] as CurrencyDetails[]);

    let chapterName = "";
    let currencyID = "";

    const LIMIT_P_C = 10;
    const LIMIT_A_C = 10;
    const LIMIT_Q_C = 0;

    const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
    const [seeMore_p_c, setSeeMore_p_c] = useState(false);

    const [isOffset_a_c, setOffset_a_c] = useState<number>(0);
    const [seeMore_a_c, setSeeMore_a_c] = useState(false);

    const [isOffset_q_c, setOffset_q_c] = useState<number>(300);
    const [seeMore_q_c, setSeeMore_q_c] = useState(false);

    useEffect(() => {
        getChapters();
        getItems();
        getCurrency();
    }, [])

    const getChapters = () => {
        ManagementServices.getAllChapters(LIMIT_P_C, isOffset_p_c).then((res) => {
            if (res.success) {
                setAllChapters(res.data);
                setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
            } else {
                setSeeMore_p_c(false);
            }
        })
    }

    const getItems = () => {
        ManagementServices.getAllItemsWithChapterIdbyAdmin(props.chapterID).then((res) => {
            if (res.success) {
                setAllItems(res.data);
                setSeeMore_a_c(res.data.length > 0 && res.data.length == LIMIT_A_C);
            } else {
                setSeeMore_a_c(false);
            }
        })
    }

    const getCurrency = () => {
        ManagementServices.getAllCurrency(LIMIT_Q_C, isOffset_q_c).then((res) => {
          if (res.success) {
            setAllCurrency(res.data);
            setSeeMore_q_c(res.data.length > 0 && res.data.length == LIMIT_Q_C);
          } else {
            setSeeMore_q_c(false);
          }
        })
    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };
    
    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    allItems.sort((a,b) => {
        const aDate = moment(a.createdAt && a.createdAt).format("YYYY/MM/DD");
        const bDate = moment(b.createdAt && b.createdAt).format("YYYY/MM/DD");

        if (aDate > bDate) return -1;
        if (aDate < bDate) return 1;
        return 0;
    })

    const paginatedAllItems = applyPagination(allItems, page, limit);
    const theme = useTheme();

    allChapters.filter((chapterId) => chapterId._id === props.chapterID).map((chapter) => {
        chapterName = chapter.chapterName;
        currencyID = chapter.currency;
    });

    return (
        <>
            <Card>
                {selectedBulkActions && (
                    <Box flex={1} p={2}>
                        <BulkActions />
                    </Box>
                )}
                {!selectedBulkActions && (
                    <CardHeader title={`All Recent Task - ${chapterName}`} key={props.chapterID} />
                )}
                <Divider />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Task Name</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell align="center">Created at</TableCell>
                                <TableCell align='center'>Task Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedAllItems.map((item) => {
                                const isCryptoOrderSelected = selectedAllItems.includes(item._id);

                                return (
                                    <TableRow
                                        sx={{'&:hover': {background: theme.colors.primary.lighter}, color: theme.palette.warning.main}}
                                        key={item._id}
                                        selected={isCryptoOrderSelected}
                                    >
                                        <TableCell>
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {item.itemName}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" noWrap></Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {item.categoryId.category}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {item.taskType}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {moment(item.createdAt && item.createdAt).format("MMM Do YY")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            {allCurrency.filter((currency) => currency._id === currencyID).map((currencyname) => (
                                                <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap key={currencyname._id}>
                                                    {currencyname.currencyName} {item.amount}
                                                </Typography>
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    <TablePagination 
                        component="div"
                        count={allItems.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25, 30]}
                    />
                </Box>
            </Card>
        </>
    )
}

export default AllTasksTable;