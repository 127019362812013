import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageHeader from "./PageHeader";
import { Container, Grid } from '@mui/material';
import AllTasksTable from "./AllTasksTable";
import { useSearchParams } from "react-router-dom";
import Footer from "src/components/Footer";

const AllTasks = () => {

    const [searchParams] = useSearchParams();
    const chapterID = searchParams.get("id");

    return (
        <>
            <Helmet>
                <title>All Tasks  - Applications</title>
            </Helmet>

            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>

            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <AllTasksTable chapterID={chapterID}/>
                    </Grid>
                </Grid>
            </Container>

            <Footer/>
        </>
    )
}

export default AllTasks;