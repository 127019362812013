import { Typography } from '@mui/material';

function PageHeader() {

    return (
        <>
            <Typography variant="h3" component="h3" gutterBottom>
                All Tasks 
            </Typography>
            <Typography variant="subtitle2">
                All Tasks of the Budget App
            </Typography>
        </>
    )
}

export default PageHeader;