import { Helmet } from "react-helmet-async";
import AllUserCategoryTable from "./AllUserCategoryTable"
import { useState } from "react";
import { CategoryDetails } from "src/models/Category";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import Footer from "src/components/Footer";
import { Container, Grid } from "@mui/material";
import PageHeader from "./PageHeader";


const AllUserCategories = () => {

    const [AllUserCategories, setAllUserCategories] = useState([] as CategoryDetails[]);

    return (
        <>
            <Helmet>
                <title>All User Categories - Applications</title>
            </Helmet>

            <PageTitleWrapper>
                <>
                    <PageHeader
                        AllUserCategories={AllUserCategories}
                        setAllUserCategories={setAllUserCategories}
                    />
                </>
            </PageTitleWrapper>

            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <AllUserCategoryTable
                            AllUserCategories={AllUserCategories}
                            setAllUserCategories={setAllUserCategories} />
                    </Grid>
                </Grid>
            </Container>

            <Footer />
        </>
    )
};

export default AllUserCategories;