import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { useState } from 'react';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import Footer from 'src/components/Footer';
import { Container, Grid } from '@mui/material';
import TemplateCategoryTable from './TemplateCategoryTable';

const TemplateCategories = () => {
  const [TemplateCategories, setTemplateCategories] = useState(
    [] as TemplateCategoryDetails[]
  );

  return (
    <>
      <Helmet>
        <title>Template Categories</title>
      </Helmet>

      <PageTitleWrapper>
        <>
          <PageHeader
            TemplateCategories={TemplateCategories}
            setTemplateCategories={setTemplateCategories}
          />
        </>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <TemplateCategoryTable
              TemplateCategories={TemplateCategories}
              setTemplateCategories={setTemplateCategories}
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default TemplateCategories;
