import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import { ManagementServices } from 'src/services/ManagementServices';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function ChapterCustomizedDialogs({
  open,
  setOpen,
  title,
  buttonTitle,
  body,
  setAllChapters,
  setSeeMore_p_c,
  LIMIT_P_C,
}) {
  const [startValue, setStartValue] = useState<Dayjs | null>(null);
  const [endValue, setEndValue] = useState<Dayjs | null>(null);

  const formatDate = (date: dayjs.Dayjs) => dayjs(date).format('YYYY-MM-DD');

  const setFilter = () => {
    ManagementServices.getAllChaptersByDateRange(
      formatDate(startValue),
      formatDate(endValue)
    ).then((res) => {
      if (res.success) {
        setAllChapters(res.data);

        setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
      } else {
        setSeeMore_p_c(false);
      }
      setOpen(false);
    });
  };

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* {body} */}

          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'10px'}
          >
            <DatePicker
              label="Start Date"
              value={startValue}
              onChange={(newValue) => {
                setStartValue(newValue);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
            -
            <DatePicker
              label="End Date"
              value={endValue}
              onChange={(newValue) => {
                setEndValue(newValue);
              }}
              renderInput={(params) => (
                <>
                  <TextField {...params} />
                </>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={setFilter}>
            {buttonTitle}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
