import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';

interface SearchBarProps {
    Placeholder: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    searchBarWidth: number | string;
    value: string;
}



const SearchBar: React.FC<SearchBarProps> = ({
    Placeholder,
    onChange,
    searchBarWidth,
    value,
}) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchIcon sx={{ marginRight: '10px' }} />
            <Input
                type="text"
                placeholder={Placeholder}
                value={value}
                onChange={onChange}
                sx={{ width: searchBarWidth, color: 'rgba(0,0,0,0.6)', fontSize: '1.1rem' }}
                disableUnderline
            />
        </Box>
    )
}


export default SearchBar