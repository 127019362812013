import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Box,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  TablePagination,
  useTheme,
  DialogTitle,
  styled,
  Avatar,
  DialogContent,
  Button,
  TextField
} from '@mui/material';
import BulkActions from '../Transactions/BulkActions';
import numeral from 'numeral';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import UserContext from 'src/context/UserContext';
import { UserDetails, VerifyStatus } from 'src/models/User';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import { activeStatus } from 'src/models/ChapterGroup';
import { DeleteTwoTone, Padding } from '@mui/icons-material';
import AllCategories from './index';
import { IconDetails } from 'src/models/Icon';
import CloseIcon from '@mui/icons-material/Close';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
`
);


interface AllCategoryTableProps {
  // className?: string;
  // allUsers: UserDetails[];
}

interface Filters {
  status?: any;
}

const applyFilters = (
  AllIcons: IconDetails[],
  filters: Filters
): any[] => {
  return AllIcons.filter((icon) => {
    let matches = true;
 
    let statusString;
    if (icon.isActive === true) {
      statusString = 'ACTIVE';
      if (filters.status && statusString !== filters.status) {
        matches = false;
      }
    } else {
      statusString = 'INACTIVE';
      if (filters.status && statusString !== filters.status) {
        matches = false;
      }
    }

    return matches;
  });
};

const applyPagination = (
  allIcons: IconDetails[],
  page: number,
  limit: number
): any[] => {
  return allIcons.slice(page * limit, page * limit + limit);
};

interface Props {
  AllIcons: IconDetails[];
  setAllIcons: React.Dispatch<
    React.SetStateAction<IconDetails[]>
  >;
}

const AllIconTable: FC<Props> = ({
    AllIcons,
    setAllIcons
}) => {
  const [selectedAllIcons, setSelectedAllIcons] = useState<string[]>([]);
  const selectedBulkActions = selectedAllIcons.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const LIMIT_P_C = 10;
  const LIMIT_A_C = 10;
  const [Status, setStatus] = useState();
  const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
  const [seeMore_p_c, setSeeMore_p_c] = useState(false);
  const [open, setOpen] = useState(false);
  const [iconName, setIconName] = useState("");
  const [iconId, setIconId] = useState("");
  const [isOffset_a_c, setOffset_a_c] = useState<number>(0);
  const [seeMore_a_c, setSeeMore_a_c] = useState(false);

  useEffect(() => {
    geticons();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const geticons = () => {
    ManagementServices.getAllIcons(LIMIT_P_C, isOffset_p_c).then(
      (res) => {
        if (res.success) {
          setAllIcons(res.data);
          
          setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
        } else {
          setSeeMore_p_c(false);
        }
      }
    );
  };

  const handleClickOpen = (iconId,status,name) => {
    setStatus(status);
    setIconId(iconId);
    setIconName(name);
    setOpen(true);
  };
  const [file, setFile] = useState(null);


  const handleChangeicon = (e) => {
       setFile(e.target.files[0]);
   };

   const clearicon =() => {
    setFile(null);
   };

  const updateIcon= () => {
      setOpen(false);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('iconId', iconId);
      formData.append('name', iconName);
      formData.append('isActive', Status);

    if(file !=null){
      ManagementServices.updateIcon(formData).then((res) => {
        if (res.success) {geticons(); setFile(null);}

        else {
          Swal.fire({
            icon: 'error',
            title: res.error,
            confirmButtonColor: '#FD7F00'
          }); 
          setFile(null);
        }
      }); }
      else{
        
        const data ={
          iconId:iconId,
          isActive:Status,
          name:iconName
        }

        
        ManagementServices.updateIconstatus(data).then((res) => {
          if (res.success) {geticons(); setFile(null);}
  
          else {
            Swal.fire({
              icon: 'error',
              title: res.error,
              confirmButtonColor: '#FD7F00'
            }); 
            setFile(null);
          }
        }); 
      }
  };

  const deleteIcon = (iconId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        ManagementServices.deleteIcon(iconId).then((res) => {
          if (res.success) {
            setAllIcons(
                AllIcons.filter(
                (IconDetails) => IconDetails._id !== iconId
              )
            );
            Swal.fire('icon Removed');
          } else {
            Swal.fire({
              icon: 'error',
              title: res.error,
              confirmButtonColor: '#FD7F00'
            });
          }
        });
      }
    });
  };

  const statusOptions = [
    {
      id: 'ALL',
      name: 'ALL'
    },
    {
      id: 'ACTIVE',
      name: 'ACTIVE'
    },
    {
      id: 'INACTIVE',
      name: 'INACTIVE'
    }
  ];


  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'ALL') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleUpdateStatus = (event) => {
    setStatus(event.target.value);
    };



  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredAllIcons = applyFilters(AllIcons, filters);
  const paginatedAllIcons = applyPagination(filteredAllIcons, page, limit);
  const selectedSomeCryptoOrders =
  selectedAllIcons.length > 0 &&
  selectedAllIcons.length < AllIcons.length;
  const selectedAllCryptoOrders =
  selectedAllIcons.length === AllIcons.length;
  const theme = useTheme();
  const statusupdateOptions = [

    {
      id: "true",
      name: activeStatus.ACTIVE
    },
    {
      id: "false",
      name: activeStatus.INACTIVE
    }
  ];
  return (
    <>
     <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}>
          Update Icon
        </BootstrapDialogTitle>
        <DialogContent dividers>
          
        <InputLabel>Status</InputLabel>
          <Select
          style={{width: `300px`}}
          id="filled-select-currency"
          variant="filled"
          value={Status}
          onChange={handleUpdateStatus}
          label="Status"
          autoWidth
          >
            {statusupdateOptions.map((statusupdateOption) => (
              <MenuItem style={{ width: `300px` }} key={statusupdateOption.id} value={statusupdateOption.id}>
                {statusupdateOption.name}
              </MenuItem>
            ))}
          </Select>
          <div style={{ height: 20 }}></div>

          <TextField
              fullWidth
              id="category"
              name="category"
              label="Icon Name"
              value={iconName}
              onChange={(e)=>setIconName(e.target.value)}

            />

            <div style={{ height: 20 }}></div>


          <InputLabel>Update Icon</InputLabel>
          
          <input
            style={{ display: 'none' }}
            accept=".svg"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleChangeicon}
          />
          <label htmlFor="contained-button-file">
              <Button
              startIcon={<UploadTwoToneIcon />} variant="contained" 
              color="primary" 
              component="span">
                Upload SVG
              </Button>
              </label>
              <Button 
              onClick={()=>clearicon()}
              variant="contained" 
              color="warning" 
              style={{ marginLeft: '30px' }}
              component="span">
                Clear
              </Button>
            
            {file && <p>File: {file.name}</p>}
          <div style={{ height: 10 }}></div>
          <Button color="primary" variant="contained" type="submit"  onClick={()=>updateIcon()}>
            Update
          </Button>
          </DialogContent>
        </BootstrapDialog> 
      <Card>
        {selectedBulkActions && (
          <Box flex={1} p={2}>
            <BulkActions />
          </Box>
        )}
        {!selectedBulkActions && (
          <CardHeader
            action={
              <Box width={150}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={filters.status || 'ALL'}
                    onChange={handleStatusChange}
                    label="Status"
                    autoWidth
                  >
                    {statusOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            }
            title="Icons"
          />
        )}
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell >Icon</TableCell>
                <TableCell>Icon Name</TableCell>

                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Created at</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody   style={{ backgroundColor: '#b6bab5' }}>
              {paginatedAllIcons.map((icon) => {  

                return (
                  <TableRow hover key={icon._id}>
                    <TableCell>
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                         {icon.icon && <img src={`data:image/svg+xml;base64,${icon.icon}`}/>}
                        
                      </Typography>
                     
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {icon.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        noWrap
                      ></Typography>
                    </TableCell>
                   
                    <TableCell align="center">
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {icon.isActive === true ? 'ACTIVE' : 'INACTIVE'}
                      </Typography>
                    </TableCell>

                    
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        align='center'
                      >
                        {moment(icon.createdAt && icon.createdAt).format("MMM Do YY")}  
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        noWrap
                      ></Typography>
                    </TableCell>

                    <TableCell align="right">
                      <Tooltip title="Edit Icon" arrow>
                        <IconButton
                          onClick={() => handleClickOpen(icon._id,icon.isActive,icon.name)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Icon" arrow>
                            <IconButton
                                onClick={() => deleteIcon(icon._id)}
                                sx={{
                                '&:hover': { background: theme.colors.error.lighter },
                                color: theme.palette.error.main
                                }}
                                color="inherit"
                                size="small"
                            >
                                <DeleteTwoToneIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredAllIcons.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>
    </>
  );
};


export default AllIconTable;
