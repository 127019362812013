import axios from "axios";
import { Util } from "src/Util";
import { AppResponse } from "src/models/Response";
import { TemplateCategoryDetails } from "src/models/TemplateCategory";

export class InventoryService {
  
  public static async getAllTemplateCategories(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<TemplateCategoryDetails[]>> {
    const url = Util.apiAuthUrl(`getAllTemplateCategories`);
    const res = await axios.get<void, AppResponse<TemplateCategoryDetails[]>>(url);
    return res;
  }

  public static async createTemplateCategory(
    data: any
  ): Promise<AppResponse<TemplateCategoryDetails>> {
    const url = Util.apiAuthUrl(`createTemplateCategory`);
    const res = await axios.post<void, AppResponse<TemplateCategoryDetails>>(url, data);
    return res;
  }

  public static async uploadJsonFile(data: any, templateCategoryId: string): Promise<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`updateJsonFile/${templateCategoryId}`);
    const res = await axios.post<any, AppResponse<any>>(ep, data, { headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data', } });
    return res;
  }
}