import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField
} from '@mui/material';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SketchPicker } from 'react-color';
import { InventoryService } from 'src/services/InventoryService';
import Swal from 'sweetalert2';
import { useState } from 'react';
import FormData from 'form-data';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validationSchema = yup.object({
  category: yup.string().required('Category Name is required')
});

interface Props {
  TemplateCategories: TemplateCategoryDetails[];
  setTemplateCategories: React.Dispatch<
    React.SetStateAction<TemplateCategoryDetails[]>
  >;
}

const TemplateCategoryPopup: React.FC<Props> = ({
  TemplateCategories,
  setTemplateCategories
}) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (event: any) => {
    setFile(event.target.files[0]);
  };

  const sendRequest = (value) => {
    setOpen(false);
    const color = bubbleColor;
    const data = {
      categoryName: value,
      categoryColor: color
    };

    if (file) {
      const formData = new FormData();
      formData.append('jsonFile', file);

      InventoryService.createTemplateCategory(data).then((res) => {
        if (res.success) {

          InventoryService.uploadJsonFile(formData, res.data._id)
            .then((res) => {
              if (res.success) {
                getTemplateCategories();
                Swal.fire('New Category Added');
              } else {
                Swal.fire({
                  icon: 'error',
                  title: res.error,
                  confirmButtonColor: '#FD7F00'
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error,
            confirmButtonColor: '#FD7F00'
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Json file is not selected',
        confirmButtonColor: '#FD7F00'
      });
    }
  };

  const getTemplateCategories = () => {
    InventoryService.getAllTemplateCategories().then((res) => {
      if (res.success) {
        setTemplateCategories(res.data);
      }
    });
  };

  const [bubbleColor, setBackgroundColor] = useState('#ffffff');

  const handlebackgroundColorChange = (color: any) => {
    setBackgroundColor(color.hex);
  };

  const formik = useFormik({
    initialValues: {
      category: ''
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendRequest(values.category);
      formik.resetForm();
    }
  });

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create Template Category
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Create a New Template Category
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="category"
              name="category"
              label="category Name"
              value={formik.values.category}
              onChange={formik.handleChange}
              error={formik.touched.category && Boolean(formik.errors.category)}
              helperText={formik.touched.category && formik.errors.category}
            />
            <div style={{ height: 10 }}></div>
            <SketchPicker
              color={bubbleColor}
              onChangeComplete={handlebackgroundColorChange}
            />
            <div style={{ height: 20 }}></div>
            <InputLabel>Add Json File</InputLabel>
            <div style={{ height: 10 }}></div>
            <input type="file" name="json-file" onChange={handleImage} />
            <div style={{ height: 30 }}></div>
            <Button color="primary" variant="contained" type="submit">
              Create
            </Button>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default TemplateCategoryPopup;
